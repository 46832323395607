import React from 'react';
import { useTheme } from '@emotion/react';
import ListItemText from '@mui/material/ListItemText';

function CustomListItemText({ children }) {
  const theme = useTheme();

  return (
    <ListItemText
      primaryTypographyProps={{
        variant: 'body2',
        color: theme.palette.text.black,
        align: 'left',
        sx: { ml: 2 },
      }}
    >
      {children}
    </ListItemText>
  );
}

export default CustomListItemText;
